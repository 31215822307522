<template>
  <div class="layout--auth">
    <div class="row">
      <div class="col-xs-12 caena-logo">
        <img
          src="@/assets/images/logo/caena-admin-logo.png"
          alt="Caena Admin Logo"
        />
      </div>
      <div class="col-xs-12 auth-content-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style scoped>
.layout--auth {
  width: 100%;
  min-height: 100vh;
  background-color: #fafafa;
}

.caena-logo {
  display: flex;
  justify-content: center;
}

.caena-logo > img {
  margin-top: 7rem;
}
</style>
